import React from "react"
import ListGroup from "react-bootstrap/ListGroup"
import Table from "react-bootstrap/Table"
import Layout from "../components/Layout"
import PageSidebar from "../components/PageSidebar"
import SEO from "../components/SEO"
import {
  PageContent,
  PageMain,
  PageParagraph,
  PageSubHeading,
  SimplePage,
} from "../components/SimplePage"
import SimplePageHeader from "../components/SimplePageHeader"

const content = [
  {
    heading: "Background",
    paragraphs: (
      <>
        <PageParagraph>
          We understand that your privacy is important to you and that you care about how your
          information is used and shared online. We respect and value the privacy of everyone who
          visits Our Site and will only collect and use information in ways that are useful to you
          and in a manner consistent with your rights and Our obligations under the law.
        </PageParagraph>
        <PageParagraph>
          This Policy applies to Our use of any and all data collected by us in relation to your use
          of Our Site. Please read this Privacy Policy carefully and ensure that you understand it.
          Your acceptance of Our Privacy Policy is deemed to occur upon your first use of Our Site.
          If you do not accept and agree with this Privacy Policy, you must stop using Our Site
          immediately.
        </PageParagraph>
      </>
    ),
  },
  {
    heading: "Definitions and Interpretation",
    paragraphs: (
      <>
        <PageParagraph>
          In this Policy the following terms shall have the following meanings:
        </PageParagraph>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Meaning</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>"Account"</td>
              <td>
                means an account required to access and/or use certain areas and features of Our
                Site;
              </td>
            </tr>
            <tr>
              <td>"Cookie"</td>
              <td>
                means a small text file placed on your computer or device by Our Site when you visit
                certain parts of Our Site and/or when you use certain features of Our Site. Details
                of the Cookies used by Our Site are set out in section 12, below;
              </td>
            </tr>
            <tr>
              <td>"Our Site"</td>
              <td>means this website, https://lumis.ai/;</td>
            </tr>
            <tr>
              <td>"EU Cookie Law"</td>
              <td>
                means the relevant parts of the Privacy and Electronic Communications (EC Directive)
                Regulations 2003 as amended in 2004, 2011 and 2015;
              </td>
            </tr>
            <tr>
              <td>"We/Us/Our"</td>
              <td>
                means Lehmann Ventures UG, registered in Germany whose registered address is
                Holdermannstr. 42B, 70567 Stuttgart.
              </td>
            </tr>
          </tbody>
        </Table>
      </>
    ),
  },
  {
    heading: "Information About Us",
    paragraphs: (
      <>
        <PageParagraph>
          Our Site, www.lumis.ai, is owned and operated by Lehmann Ventures UG. Registered address
          is Holdermannstr. 42B, 70567 Stuttgart.
        </PageParagraph>
        <PageParagraph>
          Data security department can be contacted at{" "}
          <a href="mailto:datasecurity@lumis.ai">datasecurity@lumis.ai</a>
        </PageParagraph>
      </>
    ),
  },
  {
    heading: "Scope – What Does This Policy Cover?",
    paragraphs: (
      <>
        <PageParagraph>
          This Privacy Policy applies only to your use of Our Site. It does not extend to any
          websites that are linked to from Our Site (whether We provide those links or whether they
          are shared by other users). We have no control over how your data is collected, stored or
          used by other websites and We advise you to check the privacy policies of any such
          websites before providing any data to them.
        </PageParagraph>
      </>
    ),
  },
  {
    heading: "What Data Do We Collect?",
    paragraphs: (
      <>
        <PageParagraph>
          Some data will be collected automatically by Our Site, other data will only be collected
          if you voluntarily submit it and consent to Us using it for the purposes set out in
          section 5, for example, when signing up for an Account. Depending upon your use of Our
          Site, We may collect some or all of the following data:
        </PageParagraph>
        <ListGroup>
          <ListGroup.Item>First Name</ListGroup.Item>
          <ListGroup.Item>Last Name</ListGroup.Item>
          <ListGroup.Item>Email Address</ListGroup.Item>
          <ListGroup.Item>Country</ListGroup.Item>
          <ListGroup.Item>IP Address</ListGroup.Item>
          <ListGroup.Item>Web browser type and version (automatically collected)</ListGroup.Item>
          <ListGroup.Item>Operating system (automatically collected)</ListGroup.Item>
          <ListGroup.Item>Business/company name</ListGroup.Item>
          <ListGroup.Item>Job title</ListGroup.Item>
          <ListGroup.Item>
            Professional contact information such as email addresses and telephone numbers
          </ListGroup.Item>
          <ListGroup.Item>
            A list of URLs starting with a referring site, your activity on Our Site, and the site
            you exit to (automatically collected)
          </ListGroup.Item>
        </ListGroup>
      </>
    ),
  },
  {
    heading: "How Do We Use Your Data?",
    paragraphs: (
      <>
        <PageParagraph>
          All personal data is stored securely in accordance with the EU General Data Protection
          Regulation (Regulation (EU) 2016/679) (GDPR). For more details on security see section 6,
          below.
        </PageParagraph>
        <PageParagraph>
          We use your data to provide the best possible products and services to you. This includes:
        </PageParagraph>
        <ListGroup className="mb-4">
          <ListGroup.Item>Providing and managing your Account;</ListGroup.Item>
          <ListGroup.Item>Providing and managing your access to Our Site;</ListGroup.Item>
          <ListGroup.Item>Personalising and tailoring your experience on Our Site;</ListGroup.Item>
          <ListGroup.Item>Supplying purchased tickets to you via mail;</ListGroup.Item>
          <ListGroup.Item>Send reminders when purchasing process is aborted;</ListGroup.Item>
          <ListGroup.Item>Responding to communications from you;</ListGroup.Item>
          <ListGroup.Item>
            Supplying you with email Insert Type of Email e.g. newsletters, alerts etc. that you
            have subscribed to (you may unsubscribe or opt-out at any time by Insert Description of
            Unsubscription Method;
          </ListGroup.Item>
          <ListGroup.Item>
            Analysing your use of Our Site to enable us to continually improve our site and your
            user experience;
          </ListGroup.Item>
        </ListGroup>
        <PageParagraph>
          In some cases, the collection of data may be a statutory or contractual requirement, and
          We will be limited in the [products and] services We can provide you without your consent
          for Us to be able to use such data.
        </PageParagraph>
        <PageParagraph>
          With your permission and/or where permitted by law, We may also use your data for
          marketing purposes which may include contacting you by email or telephone or text message
          (whatsapp or otherwise) with information, news and offers on Our products and services. We
          will not, however, send you any unsolicited marketing or spam and will take all reasonable
          steps to ensure that We fully protect your rights and comply with Our obligations under
          the GDPR and the Privacy and Electronic Communications (EC Directive) Regulations 2003, as
          amended in 2004, 2011 and 2015.
        </PageParagraph>
        <PageParagraph>
          Under GDPR we will ensure that your personal data is processed lawfully, fairly, and
          transparently, without adversely affecting your rights. We will only process your personal
          data if at least one of the following basis applies:
        </PageParagraph>
        <ListGroup>
          <ListGroup.Item>
            you have given consent to the processing of your personal data for one or more specific
            purposes;
          </ListGroup.Item>
          <ListGroup.Item>
            processing is necessary for the performance of a contract to which you are a party or in
            order to take steps at the request of you prior to entering into a contract;
          </ListGroup.Item>
          <ListGroup.Item>
            processing is necessary for compliance with a legal obligation to which we are subject;
          </ListGroup.Item>
          <ListGroup.Item>
            processing is necessary to protect the vital interests of you or of another natural
            person;
          </ListGroup.Item>
          <ListGroup.Item>
            processing is necessary for the performance of a task carried out in the public interest
            or in the exercise of official authority vested in the controller; and/or
          </ListGroup.Item>
          <ListGroup.Item>
            processing is necessary for the purposes of the legitimate interests pursued by us or by
            a third party, except where such interests are overridden by the fundamental rights and
            freedoms of the data subject which require protection of personal data, in particular
            where the data subject is a child.
          </ListGroup.Item>
        </ListGroup>
      </>
    ),
  },
  {
    heading: "How and Where Do We Store Your Data?",
    paragraphs: (
      <>
        <PageParagraph>
          We only keep your data for as long as We need to in order to use it as described above in
          section 5, and/or for as long as We have your permission to keep it. In any event, We will
          conduct a review to ascertain whether we need to keep your data. Your data will be deleted
          if we no longer need it in accordance with with Articles 17 and 18 GDPR. Unless expressly
          stated in this data protection declaration, the data stored by us will be deleted as soon
          as it is no longer required for its intended purpose and the deletion does not conflict
          with any statutory storage obligations.
        </PageParagraph>
        <PageParagraph>
          Some or all of your data may be stored or transferred outside of the European Economic
          Area (“the EEA”) (The EEA consists of all EU member states, plus Norway, Iceland and
          Liechtenstein). You are deemed to accept and agree to this by using Our Site and
          submitting information to Us. If We do store or transfer data outside the EEA, We will
          take all reasonable steps to ensure that your data is treated as safely and securely as it
          would be within the EEA and under the GDPR. Such steps may include, but not be limited to,
          the use of legally binding contractual terms between Us and any third parties We engage
          and the use of the EU-approved Model Contractual Arrangements.
        </PageParagraph>
        <PageParagraph>
          Notwithstanding the security measures that We take, it is important to remember that the
          transmission of data via the internet may not be completely secure and that you are
          advised to take suitable precautions when transmitting to Us data via the internet.
        </PageParagraph>
      </>
    ),
  },
  {
    heading: "Do We Share Your Data?",
    paragraphs: (
      <>
        <PageParagraph>
          We may contract with third parties to supply [products and] services to you on Our behalf.
          These may include payment processing, delivery of goods, search engine facilities,
          advertising and marketing. In some cases, the third parties may require access to some or
          all of your data. Where any of your data is required for such a purpose, We will take all
          reasonable steps to ensure that your data will be handled safely, securely, and in
          accordance with your rights, Our obligations, and the obligations of the third party under
          the law.
        </PageParagraph>
        <PageParagraph>
          We may compile statistics about the use of Our Site including data on traffic, usage
          patterns, user numbers, sales and other information. All such data will be anonymised and
          will not include any personally identifying information. We may from time to time share
          such data with third parties such as prospective investors, affiliates, partners and
          advertisers. Data will only be shared and used within the bounds of the law.
        </PageParagraph>
        <PageParagraph>
          In certain circumstances We may be legally required to share certain data held by Us,
          which may include your personal information, for example, where We are involved in legal
          proceedings, where We are complying with the requirements of legislation, a court order,
          or a governmental authority. We do not require any further consent from you in order to
          share your data in such circumstances and will comply as required with any legally binding
          request that is made of Us.
        </PageParagraph>
      </>
    ),
  },
  {
    heading: "What Happens If Our Business Changes Hands?",
    paragraphs: (
      <>
        <PageParagraph>
          We may, from time to time, expand or reduce Our business and this may involve the sale
          and/or the transfer of control of all or part of Our business. Data provided by users
          will, where it is relevant to any part of Our business so transferred, be transferred
          along with that part and the new owner or newly controlling party will, under the terms of
          this Privacy Policy, be permitted to use the data for the purposes for which it was
          originally collected by Us.
        </PageParagraph>
      </>
    ),
  },
  {
    heading: "How Can You Control Your Data?",
    paragraphs: (
      <>
        <PageParagraph>
          When you submit information via Our Site, you may be given options to restrict Our use of
          your data. We aim to give you strong controls on Our use of your data (including the
          ability to opt-out of receiving emails from Us which you may do by unsubscribing using the
          links provided in Our emails.
        </PageParagraph>
      </>
    ),
  },
  {
    heading:
      "Your Right to Withhold Information and Your Right to Withdraw Information After You Have Given it",
    paragraphs: (
      <>
        <PageParagraph>
          You may access certain areas of Our Site without providing any data at all. However, to
          use all features and functions available on Our Site you may be required to submit or
          allow for the collection of certain data.
        </PageParagraph>
        <PageParagraph>
          You may restrict your internet browser’s use of Cookies. For more information, see section
          12.
        </PageParagraph>
        <PageParagraph>
          You may withdraw your consent for Us to use your personal data as set out in section in 5
          at any time by contacting Us using the details set out in section 15, and We will delete
          Your data from Our systems. However, you acknowledge this may limit Our ability to provide
          the best possible products and services to you.
        </PageParagraph>
      </>
    ),
  },
  {
    heading: "How Can You Access Your Data?",
    paragraphs: (
      <>
        <PageParagraph>
          You have the legal right to ask for a copy of any of your personal data held by Us (where
          such data is held). Please contact Us for more details at datasecurity@lumis.ai, or using
          the contact details below in section 15.
        </PageParagraph>
      </>
    ),
  },
  {
    heading: "Which services of third parties are used?",
    paragraphs: (
      <>
        <PageSubHeading>Google Analytics</PageSubHeading>
        <PageParagraph>
          This website uses Google Analytics, a web analysis service of Google Inc. (“Google”).
          Google Analytics uses “cookies”, which are text files placed on your computer, to analyze
          how users use the site. The information generated by the cookie about your use of this
          website is transferred to a Google server in the USA and stored there. However, due to the
          activation of IP anonymisation on this website, your IP address will previously be reduced
          by Google within member states of the European Union or in other signatory states to the
          Agreement on the European Economic Area. Google will use this information for the purpose
          of evaluating your use of the website, compiling reports on website activity for website
          operators and providing other services relating to website activity and internet usage.
          Google may also transfer this information to third parties if this is required by law or
          if third parties process this data on behalf of Google. Google will not associate your IP
          address with other Google data under any circumstances.
        </PageParagraph>
        <PageParagraph>
          You may refuse the use of cookies by selecting the appropriate settings on your browser,
          however please note that if you do this you may not be able to use the full functionality
          of this website. By using this website, you consent to the processing of data about you by
          Google in the manner and for the purposes set out above. You can also prevent Google from
          collecting the data generated by the cookie by downloading and installing the browser
          plug-in available under the following link: http://tools.google.com/dlpage/gaoptout?hl=en.
        </PageParagraph>

        <PageSubHeading>Google AdWords</PageSubHeading>
        <PageParagraph>
          Our website uses Google Conversion Tracking. If you came to our site through an ad placed
          by Google, a cookie will be placed by Google AdWords. This cookie is used to determine if
          a user has accessed a page by clicking on an ad placed by Google. This information is used
          to compile statistics for AdWords customers who use conversion tracking. This gives us
          information about how many users clicked on an ad and how many users got to a page that
          contains a conversion tracking tag.
        </PageParagraph>
        <PageParagraph>
          You can deactivate tracking by deactivating all cookies via your browser settings or you
          can explicitly deactivate cookies from Google AdWords by blocking cookies from the domain
          googleleadservices.com via your browser settings.
        </PageParagraph>

        <PageSubHeading>Payment Providers</PageSubHeading>
        <PageParagraph>
          We use external providers for payment transactions. This includes Stripe and PayPal. The
          data processed by the payment providers includes name, e-mail, account numbers, credit
          card numbers, TANs, etc. This information is required to complete a transaction. However,
          the data entered will only be processed and stored by the payment service providers. This
          means that we do not receive any account or credit card related information, but only
          information whether the transaction was successful or not.
        </PageParagraph>
        <PageParagraph>
          Stripe Privacy Statement:{" "}
          <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer">
            https://stripe.com/privacy
          </a>
          <br />
          PayPal Privacy Statement:{" "}
          <a
            href="https://www.paypal.com/webapps/mpp/ua/privacy-prev"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.paypal.com/webapps/mpp/ua/privacy-prev
          </a>
        </PageParagraph>

        <PageSubHeading>Google Fonts</PageSubHeading>
        <PageParagraph>
          We use so-called Google Fonts from Google LLC, 1600 Amphitheatre Parkway, Mountain View,
          CA 94043, USA.
        </PageParagraph>
        <PageParagraph>
          Google Privacy Statement:{" "}
          <a
            href="https://www.google.com/policies/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/policies/privacy
          </a>
          <br />
          Opt-Out:{" "}
          <a
            href="https://adssettings.google.com/authenticated"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://adssettings.google.com/authenticated
          </a>
        </PageParagraph>
      </>
    ),
  },
  {
    heading: "What Cookies Do We Use and What For?",
    paragraphs: (
      <>
        <PageParagraph>
          Our Site may place and access certain first party Cookies on your computer or device.
          First party Cookies are those placed directly by Us and are used only by Us. We use
          Cookies to facilitate and improve your experience of Our Site and to provide and improve
          Our products and services. For more details, please refer to section 5, above, and to
          section 12.6 below. We have carefully chosen these Cookies and have taken steps to ensure
          that your privacy is protected and respected at all times.
        </PageParagraph>
        <PageParagraph>
          By using Our Site you may also receive certain third party Cookies on your computer or
          device. Third party Cookies are those placed by websites, services, and/or parties other
          than Us. We use third party Cookies on Our Site for analytics services, advertising
          service and payment processing. For more details, please refer to section 5, above, and to
          section 13.4 below. These Cookies are not integral to the functioning of Our Site.
        </PageParagraph>
        <PageParagraph>
          Certain features of Our Site depend on Cookies to function. EU Cookie Law deems these
          Cookies to be “strictly necessary”. These Cookies are shown below in section 12.6. Your
          consent will not be sought to place these Cookies. You may still block these Cookies by
          changing your internet browser’s settings as detailed below in section 13.08, but please
          be aware that Our Site may not work as intended if you do so. We have taken great care to
          ensure that your privacy is not at risk by allowing them.
        </PageParagraph>
        <PageParagraph>
          The following first party Cookies may be placed on your computer or device:
        </PageParagraph>
        <Table>
          <thead>
            <tr>
              <th>Name of Cookie</th>
              <th>Purpose</th>
              <th>Strictly Necessary</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>cookie_bar</td>
              <td>Checks if the cookie bar was closed</td>
              <td>Presistent</td>
            </tr>
            <tr>
              <td>session</td>
              <td>Provides a unique session identifier</td>
              <td>Session</td>
            </tr>
            <tr>
              <td>csrftoken</td>
              <td>Checks if the cookie bar was closed</td>
              <td>Session</td>
            </tr>
          </tbody>
        </Table>
        <PageParagraph>
          and the following third party Cookies may be placed on your computer or device:
        </PageParagraph>
        <Table>
          <thead>
            <tr>
              <th>Name of Cookie</th>
              <th>Provider</th>
              <th>Purpose</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                _ga
                <br />
                _gat <br />
                _gid
              </td>
              <td>Google (Google Analytics)</td>
              <td>Used for analytics and service improvements</td>
            </tr>
            <tr>
              <td>_gac_Property-ID</td>
              <td>Google (Google AdWords)</td>
              <td>Stores information about an AdWords campaign</td>
            </tr>
            <tr>
              <td>
                __stripe_mid
                <br />
                __stripe_sid
              </td>
              <td>Stripe</td>
              <td>Used for payment processing</td>
            </tr>
          </tbody>
        </Table>
      </>
    ),
  },
  {
    heading: "Summary of Your Rights under GDPR",
    paragraphs: (
      <>
        <PageParagraph>Under the GDPR, you have:</PageParagraph>
        <ListGroup className="mb-4">
          <ListGroup.Item>
            the right to request access to, deletion of or correction of, your personal data held by
            Us;
          </ListGroup.Item>
          <ListGroup.Item>the right to complain to a supervisory authority;</ListGroup.Item>
          <ListGroup.Item>be informed of what data processing is taking place;</ListGroup.Item>
          <ListGroup.Item>the right to restrict processing;</ListGroup.Item>
          <ListGroup.Item>the right to data portability;</ListGroup.Item>
          <ListGroup.Item>object to processing of your personal data;</ListGroup.Item>
          <ListGroup.Item>
            rights with respect to automated decision-making and profiling (see section 14 below).
          </ListGroup.Item>
        </ListGroup>
        <PageParagraph>
          To enforce any of the foregoing rights or if you have any other questions about Our Site
          or this Privacy Policy, please contact Us using the details set out in section 15 below.
        </PageParagraph>
      </>
    ),
  },
  {
    heading: "Automated Decision-Making and Profiling",
    paragraphs: (
      <>
        <PageParagraph>
          In the event that We use personal data for the purposes of automated decision-making and
          those decisions have a legal (or similarly significant effect) on You, You have the right
          to challenge to such decisions under GDPR, requesting human intervention, expressing their
          own point of view, and obtaining an explanation of the decision from Us.
        </PageParagraph>
        <PageParagraph>
          The right described in section 15.1 does not apply in the following circumstances:
        </PageParagraph>
        <ListGroup className="mb-4">
          <ListGroup.Item>
            The decision is necessary for the entry into, or performance of, a contract between the
            You and Us;
          </ListGroup.Item>
          <ListGroup.Item>The decision is authorised by law; or</ListGroup.Item>
          <ListGroup.Item>You have given you explicit consent.</ListGroup.Item>
        </ListGroup>
        <PageParagraph>
          Where We use your personal data for profiling purposes, the following shall apply:
        </PageParagraph>
        <ListGroup className="mb-4">
          <ListGroup.Item>
            Clear information explaining the profiling will be provided, including its significance
            and the likely consequences;
          </ListGroup.Item>
          <ListGroup.Item>
            Appropriate mathematical or statistical procedures will be used;
          </ListGroup.Item>
          <ListGroup.Item>
            Technical and organisational measures necessary to minimise the risk of errors and to
            enable such errors to be easily corrected shall be implemented; and
          </ListGroup.Item>
          <ListGroup.Item>
            All personal data processed for profiling purposes shall be secured in order to prevent
            discriminatory effects arising out of profiling.
          </ListGroup.Item>
        </ListGroup>
        <PageParagraph>We do not use automatic decision-making or profiling yet</PageParagraph>
      </>
    ),
  },
  {
    heading: "Contacting Us",
    paragraphs: (
      <>
        <PageParagraph>
          If you have any questions about Our Site or this Privacy Policy, please contact Us by
          email at datasecurity@lumis.ai. Please ensure that your query is clear, particularly if it
          is a request for information about the data We hold about you (as under section 11,
          above).
        </PageParagraph>
        <a href="mailto:datasecurity@lumis.ai" className="font-weight-bold">
          Contact Data Security Department &raquo;
        </a>
      </>
    ),
  },
  {
    heading: "Changes to Our Privacy Policy",
    paragraphs: (
      <>
        <PageParagraph>
          We may change this Privacy Policy as we may deem necessary from time to time, or as may be
          required by law. Any changes will be immediately posted on Our Site and you will be deemed
          to have accepted the terms of the Privacy Policy on your first use of Our Site following
          the alterations. We recommend that you check this page regularly to keep up-to-date.
        </PageParagraph>
      </>
    ),
  },
]

const Privacy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <SimplePage>
      <SimplePageHeader title="Privacy Policy" subtitle="Updated Mar 29. 2019" />
      <PageMain
        aside={<PageSidebar headings={content.map(({ heading }) => heading)} />}
        content={
          <>
            <PageParagraph>
              We believe you should always know what data we collect from you and how we use it.
            </PageParagraph>
            <PageParagraph>
              That's why we made it super easy to reach out to us. We have an open ear for any
              questions, concerns or suggestions.
            </PageParagraph>
            <a href="mailto:datasecurity@lumis.ai" className="font-weight-bold">
              Contact Data Security Department &raquo;
            </a>
            <PageContent content={content} />
          </>
        }
      />
    </SimplePage>
  </Layout>
)

export default Privacy
