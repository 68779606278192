import classNames from "classnames"
import React from "react"
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"
import { Link as ScrollLink } from "react-scroll"
import "./scss/index.scss"

const Sidebar = ({ headings }) => (
  <div className="sticky-top pt-6 mt-n6">
    <Card className="sidebar shadow-light-lg">
      <Card.Header tag="h5" className="border-bottom bg-white px-5">
        Table of Contents
      </Card.Header>
      <Card.Body className="px-5 py-4">
        <ListGroup variant="flush">
          {headings.map((heading, index) => (
            <ListGroup.Item
              key={`sidebar-heading-${index}`}
              className={classNames("d-flex align-items-center py-3", index === 0 && "border-0")}
            >
              <ScrollLink
                href="#"
                to={`heading-${index}`}
                smooth={true}
                duration={400}
                spy={true}
                activeClass="active"
                className="sidebar__link d-flex w-100 small text-decoration-none"
              >
                <span className="text-gray-600" style={{ width: 20 }}>
                  {++index}.
                </span>
                {heading}
              </ScrollLink>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  </div>
)

export default Sidebar
